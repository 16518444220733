import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "../../utils/cn";

const buttonVariants = cva(
  "w-auto max-w-fit inline-flex items-center justify-center whitespace-nowrap rounded-md fsc-button ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-bg-contrast text-text-contrast hover:bg-bg-tertiary hover:text-text-primary",
        outline: "border bg-transparent hover:bg-bg-tertiary text-text-primary",
        analyst: " bg-bg-contrast hover:bg-gradient-analyst text-text-contrast",
        chain: "bg-bg-contrast hover:bg-gradient-partner text-text-contrast",
        questor: "bg-bg-contrast hover:bg-gradient-explorer text-text-contrast",
        ghost: "bg-transparent hover:bg-bg-tertiary text-text-primary",
      },
      size: {
        default: "rounded px-4 py-2",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  color?: "default" | "analyst" | "chain" | "questor";
  href?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, color, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
